
import { getCookie} from "../util/index.js"
export default {
    data(){
        return {
            range:[],
            isloading:false,
            tabledata:[],
            curpage:1,
            pagesize:50,
            storeid:"",
        }
    },
    methods:{
        handleCurrentChange(val){
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            this.pagesize = val;
            this.init();
        },
        init(){
            this.axios.get(this.actions.statlist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    storeid:this.storeid,
                    range:JSON.stringify(this.range)
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.total = response.data.total*1;
                this.curpage = response.data.curpage*1;
            })
        }
    },
    created:function(){
        let storeid = getCookie("storeid");
        if(storeid)
            this.storeid = storeid;
        this.init();
    }
}
